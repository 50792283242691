import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Loader from "../ui/loader";
import "./index.scss";

const Button = props => {
  const {
    // icon,
    children,
    className,
    type,
    style,
    secondary,
    text,
    href,
    onClick,
    disabled,
    minWidth,
    loading,
    small,
    tiny,
  } = props;

  const cssClass = classNames("button", {
    [`button--${type}`]: type,
    [className]: className,
    "button--primary": !secondary && !text,
    "button--secondary": secondary,
    "button--loading": loading,
    "button--small": small,
    "button--tiny": tiny,
  });

  const buttonProps = {
    className: cssClass,
    onClick: onClick,
    style: {
      ...style,
      minWidth,
    },
    type: type ? type : "button",
    disabled,
  };

  const buttonContents = (
    <div>
      <span className="button__label">{children}</span>
      {loading ? <Loader center tiny colour="white" /> : null}
    </div>
  );

  const isExternalLink = href && href.indexOf("http") > -1;
  const isInternalLink = href && !isExternalLink;

  if (isExternalLink) {
    return (
      <a href={href} {...buttonProps}>
        {buttonContents}
      </a>
    );
  } else if (isInternalLink) {
    return (
      <Link to={href} {...buttonProps}>
        {buttonContents}
      </Link>
    );
  } else {
    return <button {...buttonProps}>{buttonContents}</button>;
  }
};

export default Button;
