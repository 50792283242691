import React, { useEffect, useState } from "react";
import { OrgDomain } from "..";
import DomainTestCase from "../domain-editor/item/test-case";
import Button from "../../../../button";
import "./index.scss";
import { useValidatedTestCases } from "../validate-test-cases-hooks";
import { OrgUserType } from "../";

interface DomainItemProps extends OrgDomain {
  editDisabled?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  readonly?: boolean;
  domains?: OrgDomain[];
}

export default function DomainItem(props: DomainItemProps) {
  const {
    domains,
    domain,
    editDisabled,
    local,
    type,
    testCases,
    onDelete,
    onEdit,
    readonly,
  } = props;

  const [checkTestCase, setCheckTestCase] = useState(false);

  const { validatedTestCases, isLoading } = useValidatedTestCases(
    {
      domain,
      local,
      type,
      testCases,
    },
    domains?.filter(d => d.domain !== domain),
    checkTestCase
  );

  useEffect(() => {
    if (checkTestCase && !isLoading) {
      setCheckTestCase(false);
    }
  }, [checkTestCase, isLoading]);

  useEffect(() => {
    if (testCases.length) {
      setCheckTestCase(true);
    }
  }, [testCases, domain, local, type]);

  return (
    <div className="org-domain-item">
      <div className="org-domain-item__row">
        <span className="org-domain-item__label">Domain:</span>
        <span className="org-domain-item__value">{domain}</span>
      </div>
      {local ? (
        <div className="org-domain-item__row">
          <span className="org-domain-item__label">Local:</span>
          <span className="org-domain-item__value">{local}</span>
        </div>
      ) : null}
      <div className="org-domain-item__row">
        <span className="org-domain-item__label">Type:</span>

        <span className="org-domain-item__value">
          {type === OrgUserType.STUDENT ? "Student" : "Teacher"}
        </span>
      </div>
      {testCases?.length > 0 ? (
        <div className="org-domain-item__row">
          <span className="org-domain-item__label">Test cases:</span>
          <div className="org-domain-item__value">
            {testCases.map(testCase => (
              <DomainTestCase
                {...testCase}
                key={testCase.email}
                domain={domain}
                domainType={type}
                local={local}
                pass={
                  validatedTestCases?.find(
                    tc =>
                      tc.email === testCase.email &&
                      tc.expectedType === testCase.expectedType
                  )?.valid
                }
              />
            ))}
          </div>
        </div>
      ) : null}
      {!readonly ? (
        <div className="org-domain-item__actions">
          <Button text tiny onClick={onDelete}>
            Delete
          </Button>
          <Button text tiny onClick={onEdit} disabled={editDisabled}>
            Edit
          </Button>
        </div>
      ) : null}
    </div>
  );
}
