import { isValidEmail } from "../../utils/validators";
import { replaceAll } from "../../utils/string";
import * as OrgsAPI from "./orgs-api";
import {
  SET_SORT_BY,
  GET_ORGANISATIONS_RESULT,
  ORG_ADDED,
  ORG_CHANGED,
  ORG_REMOVED,
  SET_ORG_SAVING,
  SET_NEW_ORG_ERROR,
  SET_EXISTING_TEACHER_LICENSE_ERROR,
  SET_DOMAIN_LICENSE_RETRY,
  SET_DOMAIN_CONFIRMATIONS,
} from "./action-types";

import { addErrorMessage, addNotification } from "../notifications/actions";
import { getSortBy } from "./selectors";

export function connect() {
  return (dispatch, getState) => {
    dispatch(getOrganisations());
  };
}

export function getOrganisations() {
  return async (dispatch, getState) => {
    const sortBy = getSortBy(getState()) || "created_at.DESC";
    const response = await OrgsAPI.getOrganisations({ sortBy });
    if (response.ok) {
      dispatch({
        type: GET_ORGANISATIONS_RESULT,
        payload: response.data,
      });
    }
  };
}

export function getOrganisationById(orgId, onLoad) {
  return async dispatch => {
    const response = await OrgsAPI.getOrganisationById(orgId);
    if (response.ok) {
      const orgData = response.data;
      dispatch(onOrgChanged(orgData));
      onLoad?.(orgData);
    }
  };
}

export function onOrgAdded(org) {
  return {
    type: ORG_ADDED,
    payload: org,
  };
}

export function onOrgChanged(org) {
  return {
    type: ORG_CHANGED,
    payload: org,
  };
}

export function onOrgRemoved(orgId) {
  return {
    type: ORG_REMOVED,
    payload: orgId,
  };
}

export function setSortBy(payload) {
  return {
    type: SET_SORT_BY,
    payload,
  };
}

function getEmailArray(emails) {
  let processed = replaceAll(emails, ",", "\n");
  processed = replaceAll(processed, ";", "\n");
  processed = processed.split("\n");
  let emailArr = processed
    .filter(email => email.trim().length)
    .map(email => email.trim());
  return emailArr
    .map(email => {
      return email.trim();
    })
    .filter(email => {
      return isValidEmail(email);
    });
}

export function saveOrganisation(payload) {
  return dispatch => {
    const { adminEmails, previousAdmins } = payload;
    let adminEmailAddresses = getEmailArray(adminEmails);

    let hasBookCreatorEmailAddress = false;

    const duplicates = adminEmailAddresses.filter((value, index, self) => {
      return self.indexOf(value) !== index;
    });

    if (duplicates.length > 0) {
      return dispatch(
        addErrorMessage(`Duplicated admin email for ${duplicates.join()}`)
      );
    }

    adminEmailAddresses = adminEmailAddresses.filter(email => {
      if (email.toLowerCase().split("@")[1] === "bookcreator.com") {
        hasBookCreatorEmailAddress = true;
        return false;
      }
      return true;
    });

    if (hasBookCreatorEmailAddress) {
      return dispatch(
        addErrorMessage(
          "Book Creator email addresses cannot be added as admins"
        )
      );
    }

    if (previousAdmins && !adminEmailAddresses.length) {
      return dispatch(
        addErrorMessage("Cannot remove all admins from an organisation")
      );
    }

    dispatch({
      type: SET_ORG_SAVING,
      payload: true,
    });
    dispatch({
      type: SET_NEW_ORG_ERROR,
      payload: false,
    });

    dispatch(
      saveToOrgsApi({
        ...payload,
        adminEmailAddresses,
      })
    );
  };
}

export function saveToOrgsApi(org) {
  return async dispatch => {
    const {
      dealAmount,
      demo,
      domainsToAdd,
      domainsToRemove,
      adminEmailAddresses,
      endDate,
      features,
      id,
      isPilot,
      licenses,
      licenseCount1000,
      name,
      previousDomains,
      quoteId,
      reseller,
      schoolSubscriptionUUID,
      startDate,
      tsm,
      xeroId,
      isNewOrg,
      domainMatchers,
    } = org;

    let orgId = id;

    const SSID = schoolSubscriptionUUID ? schoolSubscriptionUUID : id;

    let licenseKey1000;
    if (!previousDomains && (!domainsToAdd || !domainsToAdd.length)) {
      if (licenseCount1000 !== undefined) {
        const oldKey = `${SSID}-10x100`;
        const hasOldKey = licenses?.some(l => l.id === oldKey);
        licenseKey1000 = hasOldKey ? oldKey : null;
      }
    }
    const quoteIdVal = quoteId && quoteId.length ? quoteId : null;
    const xeroIdVal = xeroId && xeroId.length ? xeroId : null;
    const dealAmountVal =
      dealAmount && dealAmount > 0 ? parseInt(dealAmount, 10) : null;

    try {
      if (domainsToRemove && domainsToRemove.length > 0) {
        for (let i = 0; i < domainsToRemove.length; i++) {
          const res = await OrgsAPI.removeDomain({
            orgId,
            domain: domainsToRemove[i],
          });
          if (!res.ok) {
            throw new Error(res.message);
          }
        }
      }

      const graphqlPayload = {
        id: id,
        name: name,
        isPilot: isPilot,
        reseller,
        demo,
        features,
        adminEmails: getEmailArray(adminEmailAddresses.join(",")) || [],
        dealAmount: dealAmountVal,
        startDate,
        endDate,
        quoteId: quoteIdVal,
        tsm,
        xeroId: xeroIdVal,
        schoolSubscriptionUUID: SSID,
        licenseCount1000,
        licenseKey1000,
        domainMatchers,
      };

      const response = await OrgsAPI.saveOrganisation(graphqlPayload);
      if (response.ok) {
        const { organisation } = response.data;
        if (isNewOrg) {
          dispatch(
            onOrgAdded({
              ...organisation,
            })
          );
          orgId = organisation.id;
        } else {
          dispatch(
            onOrgChanged({
              ...organisation,
            })
          );
        }
      } else {
        throw new Error(response.message);
      }

      if (domainsToAdd && domainsToAdd.length > 0) {
        // If adding a domain for the first time we need to preview the changes
        // to see the effect on members
        if (!previousDomains) {
          dispatch(setDomainConfirmations({ domains: domainsToAdd, orgId }));
          return;
        }

        // Otherwise we can just add
        for (let i = 0; i < domainsToAdd.length; i++) {
          const res = await OrgsAPI.addDomain({
            orgId,
            domain: domainsToAdd[i],
          });
          if (!res.ok) {
            // Only retry for a single domain, the complexity for managing individual retries for multiple isn't worthwhile as it's unlikely to be required
            if (domainsToAdd.length === 1) {
              dispatch(
                setDomainLicenseRetry({ orgId: id, domain: domainsToAdd[0] })
              );
            }
            throw new Error(res.message);
          }
        }
        // fetch the updated org to reflect potential license and membersCount changes
        dispatch(getOrganisationById(orgId));
      }
      dispatch({
        type: SET_ORG_SAVING,
        payload: false,
      });
      dispatch(addNotification(`${name} successfully saved`));
    } catch (error) {
      dispatch(
        addErrorMessage(
          (error && error.message) ||
            "Something went wrong saving the organisation"
        )
      );
      dispatch({
        type: SET_NEW_ORG_ERROR,
        payload: true,
      });
      dispatch({
        type: SET_ORG_SAVING,
        payload: false,
      });
    }
  };
}

export function setOrgSaving(payload) {
  return dispatch => {
    dispatch({
      type: SET_ORG_SAVING,
      payload,
    });
  };
}

export function setDomainConfirmations(payload) {
  return {
    type: SET_DOMAIN_CONFIRMATIONS,
    payload,
  };
}

export function setExistingTeacherLicenseError(payload) {
  return {
    type: SET_EXISTING_TEACHER_LICENSE_ERROR,
    payload,
  };
}

export function setDomainLicenseRetry(payload) {
  return {
    type: SET_DOMAIN_LICENSE_RETRY,
    payload,
  };
}
