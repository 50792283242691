export function getOrganisationsState(state) {
  return state.organisations;
}

export function getOrganisationsInitialised(state) {
  return getOrganisationsState(state).initialised;
}

export function getOrganisations(state) {
  return getOrganisationsState(state).organisations;
}

export function getSortBy(state) {
  return getOrganisationsState(state).sortBy;
}

export function getOrganisationSaving(state) {
  return getOrganisationsState(state).saving;
}

export function getAddNewOrganisationError(state) {
  return getOrganisationsState(state).addNewOrgError;
}

export function getExistingTeacherLicenseError(state) {
  return getOrganisationsState(state).existingTeacherLicenseError;
}

export function getDomainLicenseRetry(state) {
  const retry = getOrganisationsState(state).domainLicenseRetry;
  return retry && retry.toJS ? retry.toJS() : retry;
}

export function getDomainConfirmations(state) {
  const { domainConfirmations } = getOrganisationsState(state);
  return domainConfirmations?.toJS();
}
