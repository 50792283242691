export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCzsPa4VZoF1EQ6ViBq3cyFwFnODGLgWys",
  authDomain: "bookcreator-dev.firebaseapp.com",
  databaseURL: "htts://bookcreator-dev.firebaseio.com",
  storageBucket: "bookcreator-dev.appspot.com",
  messagingSenderId: "409233586464",
  projectName: "bookcreator-dev",
  projectId: "bookcreator-dev",
};

export const BOOKCREATOR_APP_URL = "https://app.dev.bookcreator.com";
export const BOOKCREATOR_API_URL = "https://api.dev.bookcreator.com";
export const BOOKCREATOR_ORGS_API_URL = "https://organisations.api.dev.bookcreator.com";
export const ANALYTICS_DOMAIN = "https://analytics.api.dev.bookcreator.com";

export const BOOKCREATOR_ASSETS_URL = "https://assets.api.dev.bookcreator.com";
export const BOOKCREATOR_STORAGE_URL = "https://storage.dev.bookcreator.com";
export const BOOKCREATOR_PROMO_CODE_PREFIX =
  "bookcreator.dev://";
export const SUBSCRIPTIONS_DOMAIN = "https://subscriptions.api.dev.bookcreator.com";
export const FIREBASE_DATABASE_APPS_URL =
  "https://bookcreator-dev-apps.firebaseio.com";
export const FIREBASE_DATABASE_BLOCKS_URL =
  "https://bookcreator-dev-blocks.firebaseio.com";
export const FIREBASE_DATABASE_DELETED_ORGS_URL =
  "https://bookcreator-dev-closed-organisations.europe-west1.firebasedatabase.app/";
export const NOUN_PROJECT_DOMAIN = "https://noun.api.dev.bookcreator.com";
export const SEARCH_API_DOMAIN = "https://search.api.dev.bookcreator.com";

export const GA_ID = "";

export const SENTRY_URL = "";

export const AUTH_COOKIE_NAME = "mission_bc_auth-dev";
export const APP_AUTH_COOKIE_NAME = "mission_bc_auth-dev";
export const AUTH_COOKIE_DOMAIN = "dev.bookcreator.com";

export const RECURLY_URL = "https://bookcreator-dev.recurly.com/subscribe/";
