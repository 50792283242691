import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Redirect } from "react-router-dom";
import moment from "moment";
import {
  organisationsActions,
  getOrganisations,
  getOrganisationSaving,
  getAddNewOrganisationError,
  getExistingTeacherLicenseError,
  getDomainLicenseRetry,
  getDomainConfirmations,
} from "../../../../core/organisations";
import { notificationsActions } from "../../../../core/notifications";
import CopyIcon from "../../../../images/svg/copy.svg";
import { isValidXeroUrl } from "../../../../utils/validators";
import { getDomainArray, isValidDomain } from "./helpers";
import Header from "../../header";
import Content from "../../content";
import Form from "../../forms/form";
import FormActions from "../../forms/form-actions";
import FormButton from "../../forms/form-button";
import FlatButton from "../../button/flat";
import TextBox from "../../forms/text-box";
import TextArea from "../../forms/text-area";
import DatePicker from "../../forms/datepicker";
import FormLoading from "../../forms/form-loading";
import DisabledSection from "../../forms/disabled-section";
import DomainConfirmation from "./confirmation";
import FormHelp from "../../forms/help";
import domainBlocklist from "./domain-blocklist";
import Modal from "../../modal";
import DomainLicenseRetry from "./domain-license-retry";
import DomainPreview from "./domain-preview";
import Dropdown from "../../forms/dropdown";
import Features from "./features";
import Settings from "./settings";
import OrgTypeHandler from "./org-type-handler";
import OrgDomains from "./org-domains";
import { Tooltip } from "react-tooltip";
import SvgIcon from "../../ui/svg-icon";
import { getHasEngineerPrivileges } from "../../../../core/auth";
import { OrgUserType } from "./org-domains";

const TSMs = [
  "Adam Moncrief",
  "Ali Taki",
  "Amelia Hobbs",
  "April Hayward",
  "Catherine Cappiello",
  "Chanel Dublin",
  "David Swift",
  "Janet Burnett",
  "Jen Cousins",
  "Jon Smith",
  "Kathleen Swift",
  "Matthew Schopp",
  "Nina Jenkins",
  "Tom Towers",
  "Vicky Holt",
  "Will Pursey",
];

export const tsmOptions = [
  {
    value: null,
    label: "None",
  },
  ...TSMs.map(tsm => ({ value: tsm })),
];

class AddOrganisation extends Component {
  constructor(props) {
    super(props);
    const today = !this.isEditMode
      ? moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      : null;
    const inOneYear = !this.isEditMode ? today.clone().add(1, "years") : null;
    this.state = {
      adminEmails: "",
      dealAmount: 0,
      demo: false,
      domainConfirmation: null,
      domainLicenses: "",
      done: false,
      endDate: inOneYear,
      features: { visitLibraries: true }, //default true for new orgs
      hadLicenses: false,
      id: null,
      isPilot: false,
      isReady: !this.isEditMode,
      licenseCount1000: 0,
      name: "",
      previousDomains: null,
      previousAdmins: null,
      quoteId: "",
      reseller: false,
      schoolSubscriptionUUID: null,
      showLicenseRemovalWarning: false,
      startDate: today,
      domainMatchers: [],
      submitted: false,
      tsm: null,
      xeroId: "",
    };
  }

  componentWillMount() {
    const { organisations } = this.props;
    if (this.isEditMode && organisations.size) {
      this.loadOrg();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { organisationSaving, organisations } = this.props;
    if (
      organisationSaving &&
      !nextProps.organisationSaving &&
      !nextProps.addNewOrganisationError &&
      !nextProps.existingTeacherLicenseError
    ) {
      this.setState({
        done: true,
      });
    }
    if (
      this.isEditMode &&
      !organisations.size &&
      nextProps.organisations.size
    ) {
      this.loadOrg(nextProps);
    }
  }

  get orgIdParam() {
    const { match } = this.props;
    return match.params && match.params.orgId;
  }

  get isEditMode() {
    return this.orgIdParam;
  }

  get hasDomainLicense() {
    const { licenses } = this.state;
    if (!licenses) {
      return false;
    }
    let hasDomainLicense = false;
    Object.keys(licenses).forEach(key => {
      if (licenses[key].applyToAll) {
        hasDomainLicense = true;
      }
    });
    return hasDomainLicense;
  }

  get hasNonDomainLicenses() {
    const { licenseCount1000 } = this.state;
    return parseInt(licenseCount1000, 10) > 0;
  }

  get isDomainDisabled() {
    return this.state.hadLicenses || this.hasNonDomainLicenses;
  }

  get isQuotaDisabled() {
    return this.hasDomainLicense || this.state.domainLicenses.length;
  }

  async loadOrg(props = this.props) {
    const { addErrorMessage, getOrganisationById } = props;
    const onLoad = org => {
      if (!org) {
        return addErrorMessage("Organisation data could not be loaded");
      }
      const {
        admins,
        dealAmount,
        demo,
        domains,
        endDate,
        features,
        id,
        isPilot,
        licenses,
        name,
        quoteId,
        reseller,
        schoolSubscriptionUUID,
        startDate,
        domainMatchers,
        tsm,
        xeroId,
      } = org;

      let previousAdmins;
      let previousDomains;
      let adminEmails = "";
      let domainLicenses = "";
      if (admins.length) {
        // check to prevent all admins are removed at once
        previousAdmins = true;
        adminEmails = admins.map(admin => admin.email).join("\n");
      }

      if (domains.length) {
        previousDomains = domains.map(dom => dom.domain);
        domainLicenses = previousDomains.join("\n");
      }

      let licenseCount1000 = 0;
      if (licenses.length) {
        for (const license of licenses) {
          if (!license.applyToAll) {
            licenseCount1000 = license.quantity || 0;
          }
        }
      }
      this.setState({
        adminEmails,
        dealAmount: dealAmount ? dealAmount : 0,
        demo,
        domainLicenses,
        endDate: moment(endDate),
        features,
        hadLicenses: licenseCount1000 > 0,
        id,
        isPilot,
        isReady: true,
        licenses,
        licenseCount1000,
        name,
        previousDomains,
        previousAdmins,
        reseller,
        quoteId: quoteId ? quoteId : "",
        startDate: moment(startDate),
        domainMatchers,
        xeroId: xeroId ? xeroId : "",
        schoolSubscriptionUUID,
        tsm: tsm || null,
      });
    };
    getOrganisationById(this.orgIdParam, onLoad);
  }

  handleSubmit = e => {
    e.preventDefault();

    this.setState({
      submitted: true,
    });
    if (Object.keys(this.errors).length) {
      return;
    }
    const { domainLicenses, hadLicenses, licenseCount1000 } = this.state;
    if (
      hadLicenses &&
      !parseInt(licenseCount1000, 10) &&
      !domainLicenses.length
    ) {
      this.setState({
        showLicenseRemovalWarning: true,
      });
    } else {
      this.save();
    }
  };

  save = e => {
    const { saveOrganisation } = this.props;
    const {
      adminEmails,
      dealAmount,
      demo,
      domainLicenses,
      endDate,
      features,
      id,
      isPilot,
      licenseCount1000,
      licenses,
      name,
      previousDomains,
      previousAdmins,
      reseller,
      quoteId,
      schoolSubscriptionUUID,
      startDate,
      domainMatchers,
      tsm,
      xeroId,
    } = this.state;

    let domainArray = getDomainArray(domainLicenses);

    const domainsToIgnore = [];
    domainArray = domainArray.filter(domain => {
      let blocklisted = false;
      domainBlocklist.forEach(blocklistedDomain => {
        if (domain.indexOf(blocklistedDomain) > -1) {
          domainsToIgnore.push(domain);
          blocklisted = true;
        }
      });
      if (!isValidDomain(domain) && !domainsToIgnore.includes(domain)) {
        domainsToIgnore.push(domain);
        blocklisted = true;
      }
      return !blocklisted;
    });
    const domainsToRemove = [];
    if (previousDomains) {
      previousDomains.forEach(domain => {
        if (!domainArray.includes(domain)) {
          domainsToRemove.push(domain);
        }
      });
    }
    const domainsToAdd = domainArray.filter(domain => {
      return !previousDomains || !previousDomains.includes(domain);
    });

    const featureFlags = {
      ...features,
    };

    const domainsRemoved = previousDomains && domainArray.length === 0;

    // Turn org libraries off if all domains are removed
    if (domainsRemoved) {
      featureFlags.orgLibraries = false;
    }

    let payload;

    payload = {
      adminEmails,
      dealAmount,
      demo,
      domainLicenses,
      domainsToAdd,
      domainsToIgnore,
      domainsToRemove,
      endDate: endDate.valueOf(),
      features: featureFlags,
      id,
      isPilot,
      licenses,
      licenseCount1000: parseInt(licenseCount1000, 10),
      name,
      domainMatchers,
      previousDomains,
      previousAdmins,
      reseller,
      quoteId,
      schoolSubscriptionUUID,
      startDate: startDate.valueOf(),
      tsm,
      xeroId,
      isNewOrg: !this.isEditMode,
    };
    if (
      domainsToAdd.length ||
      domainsToRemove.length ||
      domainsToIgnore.length
    ) {
      this.setState({
        domainConfirmation: {
          ...payload,
        },
      });
    } else {
      saveOrganisation(payload);
    }
  };

  handleConfirmationConfirm = () => {
    const { saveOrganisation } = this.props;
    const { domainConfirmation } = this.state;
    saveOrganisation({ ...domainConfirmation });
    this.setState({
      domainConfirmation: null,
    });
  };

  handleConfirmationCancel = () => {
    this.setState({
      domainConfirmation: null,
    });
  };

  handleChange = (name, value) => {
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleAdminsPaste = e => {
    this.handleSpecialPaste(e, "adminEmails", this._adminEmails);
  };

  handleSpecialPaste(e, name, ref) {
    let paste = (e.clipboardData || window.clipboardData).getData("text");
    const { selectionStart, selectionEnd, value } = e.target;
    let v = `${value.slice(0, selectionStart)}${value.slice(selectionEnd)}`;
    const pastedValue = paste.toLowerCase();
    const combinedPaste = [
      v.slice(0, selectionStart),
      pastedValue,
      v.slice(selectionStart),
    ].join("");
    this.setState(
      {
        [`${name}`]: combinedPaste,
      },
      () => {
        ref.selectionStart = selectionStart + pastedValue.length;
        ref.selectionEnd = selectionStart + pastedValue.length;
      }
    );
    e.preventDefault();
    e.stopPropagation();
  }

  handleNumberBlur = (name, e) => {
    const { value } = e.target;
    if (!value || !value.length) {
      this.setState({
        [name]: 0,
      });
    }
  };

  get errors() {
    const { name, xeroId } = this.state;
    let errors = {};
    if (!name || !name.length) {
      errors["name"] = "Name is required";
    }
    if (xeroId && xeroId.length && !isValidXeroUrl(xeroId)) {
      errors["xeroId"] = "Enter the full URL from Xero";
    }
    return errors;
  }

  render() {
    const {
      adminEmails,
      dealAmount,
      demo,
      domainConfirmation,
      domainLicenses,
      done,
      endDate,
      id,
      isPilot,
      isReady,
      licenseCount1000,
      name,
      quoteId,
      reseller,
      showLicenseRemovalWarning,
      startDate,
      domainMatchers,
      submitted,
      tsm,
      xeroId,
    } = this.state;

    const {
      addNotification,
      organisationSaving,
      existingTeacherLicenseError,
      domainConfirmations,
      domainLicenseRetry,
      hasEngineerPrivileges,
      setExistingTeacherLicenseError,
    } = this.props;

    if (done) {
      return <Redirect to="/organisations" />;
    }

    const licenseInputs = (
      <Fragment>
        <TextBox
          label="1000 book licenses"
          type="number"
          min={0}
          onChange={this.handleChange.bind(this, "licenseCount1000")}
          onBlur={this.handleNumberBlur.bind(this, "licenseCount1000")}
          value={licenseCount1000}
          disabled={this.isQuotaDisabled}
        />
      </Fragment>
    );

    const domainLicenseInput = (
      <TextArea
        label="License domains"
        optional
        height={100}
        onPaste={this.handleDomainsPaste}
        onChange={this.handleChange.bind(this, "domainLicenses")}
        innerRef={c => {
          this._domains = c;
        }}
        value={domainLicenses}
        disabled={this.isDomainDisabled}
      />
    );

    const tsmEntry = tsmOptions.find(t => t.value === tsm);
    const tsmValue = {
      value: tsmEntry.value,
      label: tsmEntry.label || tsmEntry.value,
    };

    const isDomainOrg = this.hasDomainLicense || domainLicenses.length;
    const studentPortfoliosDisabled =
      !isDomainOrg ||
      !domainMatchers.some(domain => domain.type === OrgUserType.STUDENT);

    const copyToClipboard = str => {
      navigator.clipboard.writeText(str);
      addNotification("Copied to clipboard");
    };

    return (
      <div>
        <Header
          title={this.isEditMode ? "Edit organisation" : "Add new organisation"}
        />
        <Content>
          <Form
            width={450}
            onSubmit={this.handleSubmit}
            autocomplete={false}
            style={{ marginBottom: 150 }}>
            <div className="form__content">
              {!isReady ? <FormLoading /> : null}
              <TextBox
                label="Name"
                onChange={this.handleChange.bind(this, "name")}
                error={submitted && this.errors["name"]}
                value={name}
                maxLength={64}
                autoFocus={!this.isEditMode}
              />
              {this.isEditMode ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}>
                  <span
                    style={{
                      display: "block",
                      fontSize: 12,
                      color: "#40444f",
                      marginRight: 8,
                    }}>
                    ID
                  </span>
                  {/* biome-ignore lint/a11y/useKeyWithClickEvents: Just a helper span */}
                  <span
                    style={{
                      display: "inline-block",
                      fontSize: 12,
                      padding: "5px 10px",
                      borderRadius: 3,
                      background: "#eee",
                      color: "#666",
                      border: "none",
                      cursor: "context-menu",
                    }}
                    title="Click to copy to clipboard"
                    onClick={() => copyToClipboard(id)}>
                    {id}
                  </span>
                  <button
                    style={{
                      display: "block",
                      fontSize: 12,
                      width: 22,
                      height: 22,
                      padding: 5,
                      background: "#eee",
                      marginLeft: 8,
                      borderRadius: 3,
                      cursor: "pointer",
                      border: "none",
                    }}
                    type="button"
                    title="Click to copy to clipboard"
                    onClick={() => copyToClipboard(id)}>
                    <SvgIcon
                      icon={CopyIcon}
                      name="copy"
                      style={{ fill: "#666" }}
                    />
                  </button>
                </div>
              ) : null}
              <div>
                <Settings
                  demo={demo}
                  onDemoChange={() => this.setState({ demo: !demo })}
                  reseller={reseller}
                  onResellerChange={() =>
                    this.setState({ reseller: !reseller })
                  }
                  isPilot={isPilot}
                  onPilotChange={() => this.setState({ isPilot: !isPilot })}
                />
                <Features
                  features={this.state.features}
                  onChange={features => this.setState({ features })}
                  orgLibrariesDisabled={!isDomainOrg}
                  studentPortfoliosDisabled={studentPortfoliosDisabled}
                />
                <DatePicker
                  label="Start date"
                  value={startDate}
                  onChange={this.handleChange.bind(this, "startDate")}
                />
                <DatePicker
                  label="End date"
                  value={endDate}
                  onChange={this.handleChange.bind(this, "endDate")}
                />
                {this.isQuotaDisabled ? (
                  <DisabledSection title="Organisation has a domain license">
                    {licenseInputs}
                  </DisabledSection>
                ) : (
                  licenseInputs
                )}
                <TextBox
                  label="Deal amount"
                  type="number"
                  prefix="$"
                  min={0}
                  onChange={this.handleChange.bind(this, "dealAmount")}
                  onBlur={this.handleNumberBlur.bind(this, "dealAmount")}
                  value={dealAmount}
                />
                <TextBox
                  label="Xero URL"
                  optional
                  onChange={this.handleChange.bind(this, "xeroId")}
                  error={submitted && this.errors.xeroId}
                  value={xeroId}
                />
                <TextBox
                  label="Quote ID"
                  optional
                  onChange={this.handleChange.bind(this, "quoteId")}
                  value={quoteId}
                />
              </div>
              <TextArea
                label="Admin emails"
                optional
                height={100}
                onPaste={this.handleAdminsPaste}
                onChange={this.handleChange.bind(this, "adminEmails")}
                innerRef={c => {
                  this._adminEmails = c;
                }}
                value={adminEmails}
              />
              <FormHelp>
                Emails must match exactly. If unsure about capital letters enter
                multiple variants.
              </FormHelp>
              {(() => {
                if (this.isDomainDisabled) {
                  // TODO: we could later add a Delete button for preexisting quotas so we can do the switch without closing the modal
                  const tooltipContent = `To enable domains remove the existing quota licenses ${
                    this.state.hadLicenses ? "and save the org first" : ""
                  }`;
                  return (
                    <>
                      <span
                        data-tooltip-id="domainLicenseTooltip"
                        data-tooltip-content={tooltipContent}>
                        <DisabledSection title="Organisation has a quota license">
                          {domainLicenseInput}
                        </DisabledSection>
                      </span>
                      <Tooltip id="domainLicenseTooltip" />
                    </>
                  );
                }
                return domainLicenseInput;
              })()}
              <OrgDomains
                orgEditMode={this.isEditMode}
                domains={domainMatchers}
                editable={hasEngineerPrivileges}
                setDomains={domainMatchers => this.setState({ domainMatchers })}
              />
              <Dropdown
                label="TSM"
                value={tsmValue}
                menuPlacement="top"
                onChange={change => {
                  this.handleChange("tsm", change.value);
                }}
                options={tsmOptions}
              />
              <FormActions>
                <FlatButton link="/organisations">Cancel</FlatButton>
                <FormButton
                  type="submit"
                  disabled={organisationSaving}
                  loading={organisationSaving}
                  text={this.isEditMode ? "Update" : "Add"}
                />
              </FormActions>
            </div>
          </Form>
        </Content>
        {domainConfirmation ? (
          <DomainConfirmation
            domainsToAdd={domainConfirmation.domainsToAdd}
            domainsToIgnore={domainConfirmation.domainsToIgnore}
            domainsToRemove={domainConfirmation.domainsToRemove}
            onConfirm={this.handleConfirmationConfirm}
            onCancel={this.handleConfirmationCancel}
          />
        ) : null}
        {existingTeacherLicenseError ? (
          <Modal
            title="Can't add domain license"
            body="You need to set individual licenses to 0 before adding a domain license"
            modal
            onCancel={() => setExistingTeacherLicenseError(false)}
          />
        ) : null}

        {showLicenseRemovalWarning ? (
          <Modal
            title="Remove all licenses?"
            body="If you set the number of licenses to 0, teachers who have already been assigned licenses will have them removed. "
            modal
            onCancel={() => this.setState({ showLicenseRemovalWarning: false })}
            onConfirm={this.save}
          />
        ) : null}

        {domainLicenseRetry ? (
          <DomainLicenseRetry
            onSuccess={() => this.setState({ done: true })}
            {...domainLicenseRetry}
          />
        ) : null}

        {domainConfirmations ? (
          <DomainPreview
            onSuccess={() => this.setState({ done: true })}
            {...domainConfirmations}
          />
        ) : null}

        <OrgTypeHandler
          isDomainOrg={isDomainOrg}
          isQuotaOrg={this.hasNonDomainLicenses}
          onBecameDomainOrg={() => {
            // Uncomment for general release
            // this.setState({
            //   features: {
            //     ...this.state.features,
            //     orgLibraries: true,
            //   },
            // });
          }}
          onBecameQuotaOrg={() => {
            this.setState({
              features: {
                ...this.state.features,
                orgLibraries: false,
              },
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  getOrganisationSaving,
  getAddNewOrganisationError,
  getOrganisations,
  getExistingTeacherLicenseError,
  getDomainLicenseRetry,
  getDomainConfirmations,
  getHasEngineerPrivileges,
  (
    organisationSaving,
    addNewOrganisationError,
    organisations,
    existingTeacherLicenseError,
    domainLicenseRetry,
    domainConfirmations,
    hasEngineerPrivileges
  ) => ({
    organisationSaving,
    addNewOrganisationError,
    organisations,
    existingTeacherLicenseError,
    domainLicenseRetry,
    domainConfirmations,
    hasEngineerPrivileges,
  })
);

const mapDispatchToProps = {
  ...organisationsActions,
  ...notificationsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrganisation);
