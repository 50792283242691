import React, { Component } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import { organisationsActions } from "../../../core/organisations";
import OrganisationsTable from "../../components/organisations/organisations-table";
import AddEditOrganisation from "../../components/organisations/add-edit";
import DeletedOrganisations from "../../components/organisations/deleted";
import "./index.scss";

class Organisations extends Component {
  componentWillMount() {
    this.props.connect();
  }

  render() {
    const title = `Organisations`;

    return (
      <div>
        <Helmet title={title} />
        <Router history={this.props.history}>
          <Switch>
            <Route
              path={`/organisations`}
              exact
              component={OrganisationsTable}
            />
            <Route
              path={`/organisations/new`}
              exact
              component={AddEditOrganisation}
            />
            <Route
              path={`/organisations/:orgId/edit`}
              exact
              component={AddEditOrganisation}
            />
            <Route
              path={`/organisations/deleted`}
              exact
              component={DeletedOrganisations}
            />
          </Switch>
        </Router>
      </div>
    );
  }
}

const mapDispatchToProps = {
  ...organisationsActions,
};

export default connect(null, mapDispatchToProps)(Organisations);
